<template>
    <div class="flex-container w100 h100 flex-center-align flex-col scrollable-content pt24  rel mockup">
        <div class="post-preview" ref="postPreview">
            <div class="flex-container pl24 pr24">
                <div v-if="data.pageImageUrl !== false" class="mini-logo" :style="`background-image:url('${data.pageImageUrl}')`">
                </div>
                <div v-else class="mini-logo">
                </div>
                <div class="flex-container flex-col pl8">
                    <div>
                        <span class="thick">{{data.title}} </span>
                        <span> con </span>
                        <span class="thick"> {{data.sponsor}}</span>
                    </div>
                    <div class="flex-container">
                        <span class="sponsored-partnership">
                            Partnership sponsorizzata
                        </span>
                        <span class="flex-container flex-center-align flex-center-justify" style="width:10px;">
                            &#183;
                        </span>
                        
                        <img src="../../css/img/mondo.jpg" loading="lazy" class="pt2" width="12" height="12" >
                    </div>
                    
                </div>
                <img src="../../css/img/3dot.jpg" loading="lazy" class="three-dots">
            </div>
            <div class="flex-container flex-col mt16 ">
                <p class="pl24 pr24">
                    {{data.content}}
                </p>
                <p class="pl24 pr24 thick mt0">
                    {{data.link}}
                </p>
                <div class="before-image">
                    <img src="../../css/img/info.jpg" class="w100" loading="lazy">
                </div>
                <img v-if="data.postImageUrl !== false" :src="data.postImageUrl" class="w100" loading="lazy">
                <img v-else src="../../css/img/pimpa.jpeg" class="w100" loading="lazy">
                
            </div>
            <hr>
            <div class="flex-container flex-center-align reaction pl16 pr16 pb8">
                <img src="../../css/img/reaction.jpg" loading="lazy">
                <span class="pl8">
                    {{data.reactions}}
                </span>
                <div class="interactions flex-container">
                    <span>Commenti: </span>
                    <span class="pl8">{{data.comments}}</span>
                    <span class="pl8">Consivisioni: </span>
                    <span class="pl8">{{data.shares}}</span>
                </div>
            </div>
            <hr class="ml16 mr16">
            <img src="../../css/img/footerpost.png" class="w100" loading="lazy">
        </div>
    </div>
    <div class="layout-content abs w100" style="bottom:0;">
        <form-footer
        @submit-form="saveImage"
        :buttons="{disableBtn:false,submitBtn:true, submitBtnMessage:'salva immagine' ,deleteBtn:false}"
        :editing="true"
        :className= "'layout-content-footer w100 panel-footer'"
    ></form-footer>
    </div>
    
</template>
<script>
import {ref} from 'vue'
import html2canvas from 'html2canvas'
import FormFooter from '@/components/formelements/FormFooter'
export default {
    props:{
        data:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        FormFooter
    },
    setup(props){
        const saveImage = ()=>{
            html2canvas(postPreview.value).then(function(canvas) {
                saveAs(canvas.toDataURL(), 'prewiew-'+ props.data.title.replace(' ', '-' ) +'.png');
            });
        }
        const saveAs = (uri, filename) => {
            const link = document.createElement('a');
            if (typeof link.download === 'string') {
                link.href = uri;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } else {
                window.open(uri);
            }
        }
        const postPreview= ref(null)
        return {
            saveImage,
            postPreview
        }
    }
    
}
</script>
<style lang="postcss" scoped>
    .mockup{
        background-color: #eff2f5;
    }
    .post-preview{
        background-color: white;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.38;
        font-family: Helvetica, Arial, sans-serif;
        color: #1d2129;
        width: 500px;
        padding-top: 20px;
        margin-bottom: 30px;
        border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px;
         -webkit-box-shadow: #00000033;
        box-shadow: 0 1px 2px #00000033;
        & .page-logo{
            border: 1px solid #cbcbcb;
            border-radius: 50%;
            overflow: hidden;
            width: 40px;
            height: 40px;
            

        }
        & .three-dots{
            width: 23px;
            height: 13px;
            margin-left: auto;
        }
        & .thick{
            font-weight: 600;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 14px;
            color: #365899;
        }
        & .sponsored-partnership{
            font-size: 12px;
            color: #616770;
        }
        & .reaction{
            & img{
                width: 63px;
                 height: 28px;
            }
            
            & span {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.38;
                color: #777d88;
                
            }
        }
        & hr{
            border-top:solid 1px #ced0d4;
            margin-top: 0;
        }
        & .interactions{
            margin-left: auto;
            
        }
        & .before-image{
            height: 1px;
            background-color: #cbcbcb;
            position: relative;
            & img {
                position: absolute;
                width: 39px;
                height: 35px;
                border-radius: 50%;
                top: -17px;
                right: 17px;
            }
        }
        & .mini-logo{
            width: 40px;
            height: 40px;
            border: 1px solid #cbcbcb;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
        }
    }
    .scrollable-content{
        overflow-x:hidden;
        overflow-y:auto;
    }
    
</style>