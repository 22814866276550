<template>
   <div class="layout-content w100  header-content-grid h100 mockup-form">
        <div class="layout-content-header p24 pb0">
             <div class="mini-logo "></div>
             <h1 class="nomargin bebas pt24">Mockup Post</h1>
        </div>
        
        <div 
            class="flex-container flex-col nomargin p24  card-content" 
            id="campaign-card-content"
        >
            <text-input
                label="NOME PAGINA"
                :value="data.title"
                :editing="true"
                :placeholder="'inserisci il titolo della pagina'"
                :name="'title'"
                @input="onTextInput"
            >
            </text-input>
            <text-input
                label="NOME Sponsor"
                :value="data.sponsor"
                :editing="true"
                :placeholder="'inserisci lo sponsor'"
                :name="'sponsor'"
                @input="onTextInput"
            >
            </text-input>
            <text-area-input
                label="Contenuto"
                :value="data.content"
                :editing="true"
                :placeholder="'inserisci il testo del post'"
                :name="'content'"
                :className="'flex-container flex-col'"
                @input="onTextInput"
            ></text-area-input>
            <div class="flex-container flex-space-between-justyfy pt8 no-grow">
                <label class="mockup-label">
                    <div class="flex-container heading-and-error">
                        <h3 class="bebas nomargin">immagine post</h3>
                    </div>
                    <FileUpload
                        :editing="true"
                        :fileurl ="data?.postImageUrl?.src || ''"
                        :className="'uploader-container flex-container flex-col flex-center-align'"
                        @image-url-load="postImageSelected"
                    ></FileUpload>
                </label>
                <label class="mockup-label">
                    <div class="flex-container heading-and-error">
                        <h3 class="bebas nomargin">logo pagina</h3>
                    </div>
                    <FileUpload
                        :editing="true"
                        :fileurl ="data.pageImageUrl"
                        :className="'uploader-container flex-container flex-col flex-center-align'"
                        @image-url-load="pageImageSelected"
                    ></FileUpload>
                </label>
            </div>
            <text-input
                label="Link"
                :value="data.link"
                :editing="true"
                :placeholder="'inserisci link'"
                :name="'link'"
                @input="onTextInput"
            >
            </text-input>
            <text-input
                label="Numero reactions"
                :value="data.reactions"
                :editing="true"
                :placeholder="'inserisci il numero i.e. 1890'"
                :name="'reactions'"
                @input="onTextInput"
            >
            </text-input>
            <text-input
                label="Numero commenti"
                :value="data.comments"
                :editing="true"
                :placeholder="'inserisci il numero i.e. 13'"
                :name="'comments'"
                @input="onTextInput"
            >
            </text-input>
            <text-input
                label="Numero condivisioni"
                :value="data.shares"
                :editing="true"
                :placeholder="'inserisci il numero i.e. 357'"
                :name="'shares'"
                @input="onTextInput"
            >
            </text-input>
        </div>
        
    </div>
    
    
</template>
<script>
import {reactive} from 'vue'
import TextInput from '@/components/formelements/TextInput'
import TextAreaInput from '@/components/formelements/TextAreaInput'
import FileUpload from '@/components/FileUpload'
export default {
    emits:['change'],
    components:{
        TextInput,
        TextAreaInput,
        FileUpload
    },
    props:{
        mockedData:{
            type:Object,
            default:()=>{}
        }
    },
    setup(props, {emit}){


        const data = reactive({
            ...props.mockedData
        })

        const onTextInput = ({text, name}) => {
            if( name){
                data[name] = text
                emit('change', {key:name, value:text})
            }
        }
        
        const postImageSelected = (imgUrl) => {
            emit('change', {key:'postImageUrl', value:imgUrl})
        }
        const pageImageSelected = (imgUrl) => {
           emit('change', {key:'pageImageUrl', value:imgUrl})
        }

        return{
            onTextInput,
            pageImageSelected,
            postImageSelected,
            data
        }
    }
}
</script>
<style lang="postcss" scoped>
    .header-content-grid{
        height:  100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 2.8fr;
        gap: 0px 0px;
        grid-template-areas:
            "header"
            "content";
        & .card-header { grid-area: header; }
        & .card-content { 
            grid-area: content; 
            overflow-y: auto;
            overflow-x: hidden;
        }

       
    }
    .mockup-label{
        max-width: 49%;
    }
    
</style>